import React from "react";
import { NavLink } from "react-router-dom";

const TermsOfUse = () => {
  return (
    <div className="container row post-page mt_12rem">
      <article className="col-md-12 termsOfUse">
        <NavLink to="/" className="link-left-arrow">
          Retourner à l'accueil
        </NavLink>
        <h2 className="post-title mrt-4">Mentions légales</h2>
        <br />
        <h3 className="post-title">Définitions :</h3>
        <p>
          <b>Client : </b> tout professionnel ou personne physique capable au
          sens des articles 1123 et suivants du code civil, ou personne morale,
          qui visite le site objet des présentes conditions générales.
        </p>
        <p>
          <b>Prestations et Services : </b>
          <a href="https://www.sol4d.fr">
            <b> https://www.sol4d.fr </b>
          </a>
          met à disposition des Clients :
        </p>
        <p>
          <b>Contenu :</b> Ensemble des éléments constituants l'information
          présente sur le site, notamment textes - images - vidéos.
        </p>
        <p>
          <b>Informations clients :</b> Ci après dénommé « Information (s) » qui
          correspondent à l'ensemble des données personnelles susceptibles
          d'être détenues par
          <a href="https://www.sol4d.fr">
            <b> https://www.sol4d.fr </b>
          </a>
          pour la gestion de votre compte, de la gestion de la relation client
          et à des fins d'analyses et de statistiques.
        </p>
        <p>
          <b>Utilisateur :</b> Internaute se connectant, utilisant le site
          susnommé.
        </p>
        <p>
          <b>Informations personnelles :</b> « Les informations qui permettent,
          sous quelque forme que ce soit, directement ou non, l'identification
          des personnes physiques auxquelles elles s' appliquent » (article 4 de
          la loi n° 78-17 du 6 janvier 1978).
        </p>
        <p>
          Les termes « données à caractère personnel », « personne concernée »,
          « sous traitant » et « données sensibles » ont le sens défini par le
          Règlement Général sur la Protection des Données (RGPD : n° 2016-679)
        </p>
        <br />
        <h3 className="post-title">1. Présentation du site internet.</h3>
        <p>
          En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la
          confiance dans l'économie numérique, il est précisé aux utilisateurs
          du site internet
          <a href="https://www.sol4d.fr">
            <b> https://www.sol4d.fr </b>
          </a>
          l'identité des différents intervenants dans le cadre de sa réalisation
          et de son suivi:
        </p>
        <p>
          <b>Propriétaire :</b> SOL4D S.A.S au capital de 3 000 € - SIRET
          98057949400017 - TVA intracommunautaire : FR94980579494 - Siège social
          : 22 B RUE DE BALLAINVILLIERS 91160 LONGJUMEAU, FRANCE.
        </p>
        <p>
          <b>Délégué à la protection des données :</b> SOL4D -
          <span className="color-secondary"> contact@sol4d.fr</span>
        </p>
        <br />
        <h3 className="post-title">
          2. Conditions générales d'utilisation du site et des services
          proposés.
        </h3>
        <p>
          L'utilisation du site
          <a href="https://www.sol4d.fr"> https://www.sol4d.fr </a>
          implique l'acceptation pleine et entière des conditions générales
          d'utilisation ci-après décrites. Ces conditions d'utilisation sont
          susceptibles d'être modifiées ou complétées à tout moment, les
          utilisateurs du site
          <a href="https://www.sol4d.fr"> https://www.sol4d.fr </a>
          sont donc invités à les consulter de manière régulière.
        </p>
        <p>
          Ce site est normalement accessible à tout moment aux utilisateurs. Une
          interruption pour raison de maintenance technique peut être toutefois
          décidée par
          <a href="https://www.sol4d.fr"> https://www.sol4d.fr </a>, qui
          s'efforcera alors de communiquer préalablement aux utilisateurs les
          dates et heures de l'intervention.
        </p>
        <p>
          Le site
          <a href="https://www.sol4d.fr"> https://www.sol4d.fr </a>
          est mis à jour régulièrement par
          <b> https://www.sol4d.fr</b> responsable. De la même façon, les mentions légales peuvent
          être modifiées à tout moment : elles s'imposent néanmoins à
          l'utilisateur qui est invité à s'y référer le plus souvent possible
          afin d'en prendre connaissance.
        </p>
        <br />
        <h3 className="post-title">3. Description des services fournis.</h3>
        <p>
          Le site internet
          <a href="https://www.sol4d.fr"> https://www.sol4d.fr </a>a
          pour objet de fournir une information concernant l'ensemble des
          activités de la société.
        </p>
        <p>
          <b>SOL4D</b> s'efforce de fournir sur le site
          <a href="https://www.sol4d.fr"> https://www.sol4d.fr </a>
          des informations aussi précises que possible. Toutefois, il ne pourra
          être tenu responsable des omissions, des inexactitudes et des carences
          dans la mise à jour, qu'elles soient de son fait ou du fait des tiers
          partenaires qui lui fournissent ces informations.
        </p>
        <p>
          Toutes les informations indiquées sur le site
          <a href="https://www.sol4d.fr"> https://www.sol4d.fr </a>
          sont données à titre indicatif, et sont susceptibles d'évoluer. Par
          ailleurs, les renseignements figurant sur le site
          <a href="https://www.sol4d.fr"> https://www.sol4d.fr </a>
          ne sont pas exhaustifs. Ils sont donnés sous réserve de modifications
          ayant été apportées depuis leur mise en ligne.
        </p>
        <br />
        <h3 className="post-title">
          4. Limitations contractuelles sur les données techniques.
        </h3>
        <p>
          Le site utilise la technologie JavaScript. Le site Internet ne pourra
          être tenu responsable de dommages matériels liés à l'utilisation du
          site. De plus, l'utilisateur du site s'engage à accéder au site en
          utilisant un matériel récent, ne contenant pas de virus et avec un
          navigateur de dernière génération mis à jour.
        </p>
        <br />
        <h3 className="post-title">
          5. Propriété intellectuelle et contrefaçons.
        </h3>
        <p>
          <b>SOL4D</b> est propriétaire des droits de propriété
          intellectuelle ou détient les droits d'usage sur tous les éléments
          accessibles sur le site, notamment les textes, images, graphismes,
          logos, icônes, sons, logiciels.
        </p>
        <p>
          Toute reproduction, représentation, modification, publication,
          adaptation de tout ou partie des éléments du site, quel que soit le
          moyen ou le procédé utilisé, est interdite, sauf autorisation écrite
          préalable de : <b>SOL4D</b>.
        </p>
        <p>
          Toute exploitation non autorisée du site ou de l'un quelconque des
          éléments qu'il contient sera considérée comme constitutive d'une
          contrefaçon et poursuivie conformément aux dispositions des articles
          L.335-2 et suivants du Code de Propriété Intellectuelle.
        </p>
        <br />
        <h3 className="post-title">6. Limitations de responsabilité.</h3>
        <p>
          <b>SOL4D</b> ne pourra être tenu responsable des dommages directs
          et indirects causés au matériel de l'utilisateur, lors de l'accès au
          site
          <a href="https://www.sol4d.fr"> https://www.sol4d.fr </a>, et
          résultant soit de l'utilisation d'un matériel ne répondant pas aux
          spécifications indiquées au point 4, soit de l'apparition d'un bug ou
          d'une incompatibilité.
        </p>
        <p>
          <b>SOL4D</b> ne pourra également être tenu responsable des dommages
          indirects (tels par exemple qu'une perte de marché ou perte d'une
          chance) consécutifs à l'utilisation du site
          <a href="https://www.sol4d.fr"> https://www.sol4d.fr </a>.
        </p>
        <p>
          Des espaces interactifs (possibilité de poser des questions dans
          l'espace contact) sont à la disposition des utilisateurs.
          <b> SOL4D</b> se réserve le droit de supprimer, sans mise en
          demeure préalable, tout contenu déposé dans cet espace qui
          contreviendrait à la législation applicable en France, en particulier
          aux dispositions relatives à la protection des données. Le cas
          échéant, <b>SOL4D</b> se réserve également la possibilité de mettre
          en cause la responsabilité civile et/ou pénale de l'utilisateur,
          notamment en cas de message à caractère raciste, injurieux, diffamant,
          ou pornographique, quel que soit le support utilisé (texte,
          photographie…).
        </p>
        <br />
        <h3 className="post-title">7. Gestion des données personnelles.</h3>
        <p>
          En France, les données personnelles sont notamment protégées par la
          loi n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004,
          l'article L. 226-13 du Code pénal et le Règlement Européen n° 2016/679
          (RGPD).
        </p>
        <p>
          À l'occasion de l'utilisation du site
          <a href="https://www.sol4d.fr"> https://www.sol4d.fr </a>,
          peuvent être recueillies : l'URL des liens par l'intermédiaire
          desquels l'utilisateur a accédé au site
          <a href="https://www.sol4d.fr"> https://www.sol4d.fr </a>, le
          fournisseur d'accès de l'utilisateur, l'adresse de protocole Internet
          (IP) de l'utilisateur.
        </p>
        <p>
          En tout état de cause <b>SOL4D</b> ne collecte des informations
          personnelles relatives à l'utilisateur que pour le besoin de certains
          services proposés par le site
          <a href="https://www.sol4d.fr"> https://www.sol4d.fr </a>.
          L'utilisateur fournit ces informations en toute connaissance de cause,
          notamment lorsqu'il procède par lui-même à leur saisie. Il est alors
          précisé à l'utilisateur du site
          <a href="https://www.sol4d.fr"> https://www.sol4d.fr </a>
          l'obligation ou non de fournir ces informations.
        </p>
        <p>
          Conformément aux dispositions des articles 38 et suivants de la loi
          78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux
          libertés, tout utilisateur dispose d'un droit d'accès, de
          rectification et d'opposition aux données personnelles le concernant,
          en effectuant sa demande écrite et signée, accompagnée d'une copie du
          titre d'identité avec signature du titulaire de la pièce, en précisant
          l'adresse à laquelle la réponse doit être envoyée.
        </p>
        <p>
          Aucune information personnelle de l'utilisateur du site
          <a href="https://www.sol4d.fr"> https://www.sol4d.fr </a>
          n'est publiée à l'insu de l'utilisateur, échangée, transférée, cédée
          ou vendue sur un support quelconque à des tiers. Seule l'hypothèse du
          rachat de <b> SOL4D</b> et de ses droits permettrait la
          transmission des dites informations à l'éventuel acquéreur qui serait
          à son tour tenu de la même obligation de conservation et de
          modification des données vis-à-vis de l'utilisateur du site.
        </p>
        <p>
          Les bases de données sont protégées par les dispositions de la loi du
          1er juillet 1998 transposant la directive 96/9 du 11 mars 1996
          relative à la protection juridique des bases de données.
        </p>
        <br />
        <h3 className="post-title">8. Liens hypertextes et cookies.</h3>
        <p>
          Le site
          <a href="https://www.sol4d.fr"> https://www.sol4d.fr </a>
          contient un certain nombre de liens hypertextes vers d'autres sites,
          mis en place avec l'autorisation de
          <b>SOL4D</b>. Cependant, <b>SOL4D</b> n'a pas la possibilité de
          vérifier le contenu des sites ainsi visités, et n'assumera en
          conséquence aucune responsabilité de ce fait.
        </p>
        <p>
          La navigation sur le site
          <a href="https://www.sol4d.fr"> https://www.sol4d.fr </a>
          est susceptible de provoquer l'installation de cookie(s) sur
          l'ordinateur de l'utilisateur. Un cookie est un fichier de petite
          taille, qui ne permet pas l'identification de l'utilisateur, mais qui
          enregistre des informations relatives à la navigation d'un ordinateur
          sur un site. Les données ainsi obtenues visent à faciliter la
          navigation ultérieure sur le site, et ont également vocation à
          permettre diverses mesures de fréquentation.
        </p>
        <p>
          Le refus d'installation d'un cookie peut entraîner l'impossibilité
          d'accéder à certains services. L'utilisateur peut toutefois configurer
          son ordinateur de la manière suivante, pour refuser l'installation des
          cookies :
        </p>
        <ul>
          <li>
            Dans Internet Explorer : onglet outil (pictogramme en forme de
            rouage en haut a droite) / options internet. Cliquez sur
            Confidentialité et choisissez Bloquer tous les cookies. Validez sur
            Ok.
          </li>
          <li>
            Dans Firefox : en haut de la fenêtre du navigateur, cliquez sur le
            bouton Firefox, puis aller dans l'onglet Options. Cliquez sur
            l'onglet Vie privée. Paramétrez les Règles de conservation sur :
            utiliser les paramètres personnalisés pour l'historique. Enfin
            décochez-la pour désactiver les cookies.
          </li>
          <li>
            Dans Safari : Cliquez en haut à droite du navigateur sur le
            pictogramme de menu (symbolisé par un rouage). Sélectionnez
            Paramètres. Cliquez sur Afficher les paramètres avancés. Dans la
            section "Confidentialité", cliquez sur Paramètres de contenu. Dans
            la section "Cookies", vous pouvez bloquer les cookies.
          </li>
          <li>
            Dans Chrome : Cliquez en haut à droite du navigateur sur le
            pictogramme de menu (symbolisé par trois lignes horizontales).
            Sélectionnez Paramètres. Cliquez sur Afficher les paramètres
            avancés. Dans la section "Confidentialité", cliquez sur préférences.
            Dans l'onglet "Confidentialité", vous pouvez bloquer les cookies.
          </li>
        </ul>
        <br />
        <h3 className="post-title">
          9. Droit applicable et attribution de juridiction.
        </h3>
        <p>
          Tout litige en relation avec l'utilisation du site
          <a href="https://www.sol4d.fr"> https://www.sol4d.fr </a>
          est soumis au droit français. Il est fait attribution exclusive de
          juridiction aux tribunaux compétents de Paris.
        </p>
        <br />
        <h3 className="post-title">10. Les principales lois concernées.</h3>
        <p>
          Loi n° 78-17 du 6 janvier 1978, notamment modifiée par la loi n°
          2004-801 du 6 août 2004 relative à l'informatique, aux fichiers et aux
          libertés.
        </p>
        <p>
          Loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie
          numérique.
        </p>
        <br />
        <h3 className="post-title">11. Lexique.</h3>
        <p>
          <b>Utilisateur :</b> Internaute se connectant, utilisant le site
          susnommé.
        </p>
        <p>
          <b>Informations personnelles :</b> « Les informations qui permettent,
          sous quelque forme que ce soit, directement ou non, l'identification
          des personnes physiques auxquelles elles s'appliquent » (article 4 de
          la loi n° 78-17 du 6 janvier 1978).
        </p>
      </article>
    </div>
  );
};

export default TermsOfUse;
