import { Link } from "react-router-dom";
import ContactUs from "./ContactUs";
import "../css/footer.css";
import sol4d_v2_Sans_BG from "../assets/sol4d_v2_Sans_BG.png";
const Footer = ({ anchorContactUsRef }) => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  function getCurrentYear() {
    return new Date().getFullYear();
  }
  return (
    <footer
      id="contact"
      ref={anchorContactUsRef}
      className="block block--secondary block--skewed-top-right footer-container"
    >
      <ContactUs />
      <div className="footer__brand">
        <img src={sol4d_v2_Sans_BG} alt="SOL-4D Logo" />
        <div className="footer-copyright">
          <p className="copyright">Copyright {getCurrentYear()} SOL 4D </p>
          <Link
            to="/termsofuse"
            onClick={scrollToTop}
            className="router-link tems-of-use"
          >
            Les mentions légales
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
